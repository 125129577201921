import { UserRoles } from "./global"
import { MdContactPhone } from "react-icons/md";

export interface MenuInterface {
    icon: React.FC;
    title: string;
    link: string;
    pathname: string;
    roles?: UserRoles[];
    subMenus?: MenuInterface[];
  }
  
export const MENUS: MenuInterface[] = [
    {
        icon: MdContactPhone,
        title: "Clients",
        link: "/clients",
        pathname: "clients"
    },
]
