import {useForm} from "react-hook-form";
import CardTable from "../components/card/CardTable";
import {ColumnInterface} from "../components/table/typings";
import {APP_API_URL} from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import {useQueries} from "../hooks/useQueries";
import Card from "../components/card/Card";
import {Form} from "../components/form/Form";
import {Select2} from "../components/form/Select";
import {useRef} from "react";
import SimpleButton from "../components/button/SimpleButton";
import {BiSearchAlt2} from "react-icons/bi";
import {CancelButton} from "../components/button/CustomButton";

export default function TransactionPage() {
    /**
     * Hooks
     */
    const {
        baseApiUrl,
        pagination,
        title,
        setPagination,
    } = useHeaderFunctionPage({baseApiUrl: "client", title: "Clients"});
    const mode = useRef(null);
    const product = useRef(null);
    const start = useRef(null);
    const end = useRef(null);

    const queryKey = [
        `${baseApiUrl}s`,
        pagination.page,
        pagination.perPage,
        mode.current,
        product.current,
        start.current,
        end.current
    ];
    const {
        register,
        reset,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm();
    /**
     * Query
     */
    const handleSubmitFilterForm = (values: any) => {
        mode.current = values.mode?.name;
        product.current = values.products?.name;
        start.current = values.starts?.name;
        end.current = values.ends?.name;
        console.log(values)
    };
    const handleCancelForm = () => {
        reset();
        mode.current = null;
        product.current = null;
        start.current = null;
        end.current = null;
    };
    const {data: products} = useQueries(
        APP_API_URL +
        `products?paginate=0`,
        ["products"]
    );
    const {data: starts} = useQueries(
        APP_API_URL +
        `starts?paginate=0`,
        ["starts"]
    );
    const {data: ends} = useQueries(
        APP_API_URL +
        `ends?paginate=0`,
        ["ends"]
    );
    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}${
            mode.current ? `&mode=${mode.current}` : ""}${
            product.current ? `&product=${product.current}` : ""}${
            start.current ? `&start=${start.current}` : ""}${
            end.current ? `&end=${end.current}` : ""}`,
        queryKey
    );
    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Entreprise",
            key: "company_name",
            dataIndex: "company_name",
        },
        {
            title: "Téléphone",
            key: "phone_number",
            dataIndex: "phone_number",
        },
        {
            title: "Produit",
            key: "product",
            dataIndex: "product",
        },
        {
            title: "Poids(tonnes)",
            key: "weight",
            dataIndex: "weight",
        },
        {
            title: "Point de départ",
            key: "start",
            dataIndex: "start",
        },
        {
            title: "Point d'arrivée",
            key: "end",
            dataIndex: "end",
        },
        {
            title: "Date du transport",
            key: "transport_date",
            dataIndex: "transport_date",
        },
        {
            title: "Mode de transport",
            key: "mode",
            dataIndex: "mode",
        },
        {
            title: "Date",
            key: "created_at",
            dataIndex: "created_at",
        },
    ];
    /**
     * UI
     */
    return (
        <>
            <Card
                title={"Filtres"}
                extras={[]}>
                <Form
                    loading={isLoading}
                    onSubmit={handleSubmit(handleSubmitFilterForm)}
                >
                    <div className={"grid grid-cols-4 gap-2"}>
                        <div>
                            <Select2
                                control={control}
                                placeholder={"Selectionner le produit"}
                                label={"Produits"}
                                register={register}
                                error={errors?.products}
                                name={"products"}
                                textKeyItem={"name"}
                                valueKeyItem={"name"}
                                items={products?.data.data}
                                required={false}
                            />
                        </div>
                        <div>
                            <Select2
                                control={control}
                                placeholder={"Selection le point de départ"}
                                label={"Point de départ"}
                                register={register}
                                error={errors?.starts}
                                name={"starts"}
                                textKeyItem={"name"}
                                valueKeyItem={"name"}
                                items={starts?.data.data}
                                required={false}
                            />
                        </div>
                        <div>
                            <Select2
                                control={control}
                                placeholder={"Selection le point d'arrivée"}
                                label={"Point d'arrivé"}
                                register={register}
                                error={errors?.ends}
                                name={"ends"}
                                textKeyItem={"name"}
                                valueKeyItem={"name"}
                                items={ends?.data.data}
                                required={false}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Selectionner le mode de transport"
                                control={control}
                                name={"mode"}
                                error={errors?.mode}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"name"}
                                items={[{name: "Transit"}, {name: "Local"}]}
                                label="Mode"
                                required={false}
                            />
                        </div>
                        <div className={"flex items-end gap-2"}>
                            <SimpleButton
                                type={"submit"}
                                className="bg-soft-primary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center mt-2     "
                            >
                                <BiSearchAlt2 className={"mr-2"}/> Filtrer
                            </SimpleButton>
                            <CancelButton onClick={handleCancelForm}/>
                        </div>
                    </div>
                </Form>
            </Card>
            <CardTable
                extras={[]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />
        </>
    );
}
